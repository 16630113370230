import React, { useState } from 'react';
import dataPrays from '../data/dataPrays';
import '../styles/Pray.css';

const Pray: React.FC = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState<number | null>(null);
  const [extraIndex, setExtraIndex] = useState<number>(0);

  const openLightbox = (index: number) => {
    setCurrentImageIndex(index);
    setExtraIndex(0);
  };

  const closeLightbox = () => {
    setCurrentImageIndex(null);
    setExtraIndex(0);
  };

  const previousImage = () => {
    if (extraIndex > 0) {
      setExtraIndex((prevIndex) => prevIndex - 1);
    } else if (currentImageIndex! > 0) {
      setCurrentImageIndex((prevIndex) => prevIndex! - 1);
      setExtraIndex(dataPrays[currentImageIndex! - 1].extras.length - 1);
    } else {
      setCurrentImageIndex(dataPrays.length - 1);
      setExtraIndex(dataPrays[dataPrays.length - 1].extras.length - 1);
    }
  };

  const nextImage = () => {
    if (extraIndex < dataPrays[currentImageIndex!].extras.length - 1) {
      setExtraIndex((prevIndex) => prevIndex + 1);
    } else if (currentImageIndex! < dataPrays.length - 1) {
      setCurrentImageIndex((prevIndex) => prevIndex! + 1);
      setExtraIndex(0);
    } else {
      setCurrentImageIndex(0);
      setExtraIndex(0);
    }
  };

  const displayedImage =
    currentImageIndex !== null && extraIndex < dataPrays[currentImageIndex].extras.length
      ? dataPrays[currentImageIndex].extras[extraIndex].src
      : dataPrays[currentImageIndex!]?.src;

  return (
    <section id="pray" className="pray-section">
      <h2>Modlitby</h2>
      <p>
        Táto sekcia obsahuje modlitby pre duchovné vedenie a vnútorný pokoj.
      </p>
      <div className="pray-images">
        {dataPrays.map((image, index) => (
          <img
            key={image.id}
            src={image.src}
            alt={image.alt}
            onClick={() => openLightbox(index)}
          />
        ))}
      </div>

      {currentImageIndex !== null && (
        <div className="lightbox active">
          <span className="close-btn" onClick={closeLightbox}>
            &times;
          </span>
          <span className="arrow left" onClick={previousImage}>
            &#10094;
          </span>
          <img
            src={displayedImage}
            alt=""
            className="lightbox-image"
          />
          <span className="arrow right" onClick={nextImage}>
            &#10095;
          </span>
        </div>
      )}
    </section>
  );
};

export default Pray;
