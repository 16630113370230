import React, { useState } from 'react';
import '../styles/About.css';
import omseImage1 from '../assets/images/about/omse1.jpg';
import omseImage2 from '../assets/images/about/omse2.jpg';

const About: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <section id="about" className="about-section">
      <h3 className="decorated-header">Farské Oznamy</h3>
      <h4>Hlavný Oznam</h4>
      <p>
        Každú 4. nedeľu v mesiaci bude adorácia za <b>vnútorné uzdravenie</b> v katolíckom kostole v Zbrojníkoch.<br />
        Program začína o 15:00 vyložením sviatosti oltárnej, Korunka Božieho milosrdenstva, adorácia s osobitným požehnaním a o 17:00 svätá omša s kázňou.<br />
        <b>Najbližšia uzdravovacia omša bude v sobotu 22. marca 2025.</b>
      </p>
      <h4>Farské Oznamy</h4>
      <p>
        <a href="#" onClick={openModal}>Rozpis Sv. Omší</a>
      </p>

      {/* Modal for showing images */}
      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={closeModal}>&times;</span>
            <div className="modal-images">
              <img src={omseImage1} alt="Rozpis Sv. Omší 1" />
              <img src={omseImage2} alt="Rozpis Sv. Omší 2" />
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default About;
